import { AutoCompleteFilter, RangeDatePicker, Table } from "components";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useRoomColumns } from "./list.columns";
import { ITeacherWorkReport } from "../../types";

export const List = () => {
  const thisMonthStart = dayjs().startOf("month").toISOString();
  const thisMonthEnd = dayjs().toISOString();

  const columns = useRoomColumns();
  const { t } = useTranslation();

  const renderHeader = (
    <div
      style={{
        display: "flex",
        gap: "10px",
        alignItems: "center",
      }}
    >
      <RangeDatePicker
        defaultValues={[dayjs(thisMonthStart), dayjs(thisMonthEnd)]}
      />

      <AutoCompleteFilter
        placeholder={t("general.teacher")!}
        filterName="teacherIds"
        optionsUrl="employees/pagin"
        multiple
        exQueryParams={{
          limit: 200,
          page: 1,
          type: "teacher",
        }}
      />
    </div>
  );

  return (
    <div>
      <Table<ITeacherWorkReport>
        dataUrl="/analitics/journal-activity"
        columns={columns}
        title={t("general.teacher-work-report")}
        headerChildren={renderHeader}
        exQueryParams={{
          fromDate: thisMonthStart,
          toDate: thisMonthEnd,
        }}
        processingParams={(params) => {
          return {
            ...params,
            teacherIds: JSON.stringify(
              JSON.parse(params?.teacherIds || "[]")?.map(
                (value: Record<string, any>) => value?._id
              )
            ),
          };
        }}
      />
    </div>
  );
};
