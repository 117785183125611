import TeacherWorkReport from "pages/analitics/teacherWorkReport";
import { Parents } from "pages/school/parents/container/Parents";
import { Integrations } from "pages/settings/integrations/container/Integrations";
import { lazy } from "react";
import { Navigate, RouteObject } from "react-router-dom";
import { IRoles_ } from "services/useRoleManager";

const Class = lazy(() => import("pages/school/class"));
const Subjects = lazy(() => import("pages/school/subjects"));
const Rooms = lazy(() => import("pages/school/rooms"));
const Students = lazy(() => import("pages/school/students"));

const BLACK_PAGE = lazy(() => import("pages/black_page"));
const Dashboard = lazy(() => import("pages/dashboard"));
const Schedule = lazy(() => import("pages/schedule"));
const Task = lazy(() => import("pages/task"));
const Journal = lazy(() => import("pages/journal"));
const Chat = lazy(() => import("pages/chat"));

const MonthlyAssessment = lazy(() => import("pages/monthly-assessment"));
const Cash = lazy(() => import("pages/finance/cash/ui"));
const Salary = lazy(() => import("pages/finance/salary/list"));
const MonthlySalary = lazy(() => import("pages/finance/salary/action"));

const Appropriation = lazy(() => import("pages/analitics"));

const LessonTime = lazy(() => import("pages/settings/lessonTime"));
const Receipt = lazy(() => import("pages/settings/receipt"));
const Buildings = lazy(() => import("pages/settings/building"));
const Branch = lazy(() => import("pages/managment/branch"));
const Roles = lazy(() => import("pages/managment/roles"));
const Employees = lazy(() => import("pages/managment/employees"));
const Quarter = lazy(() => import("pages/settings/quarter"));
const EvalutionSystem = lazy(() => import("pages/settings/evalution-system"));
const TransactionType = lazy(() => import("pages/settings/transaction-type"));
const PaymentMethod = lazy(() => import("pages/settings/payment-method"));
const AbonimentList = lazy(() => import("pages/settings/aboniment/list"));
const AbonimentInfo = lazy(() => import("pages/settings/aboniment/info"));
const Holiday = lazy(() => import("pages/settings/holiday"));

export const privateRoutes: (RouteObject & { role: keyof IRoles_ })[] = [
  {
    element: <Navigate to="/home" replace />,
    path: "*",
    role: "_id",
  },
  {
    element: <Navigate to="/home" replace />,
    path: "/",
    role: "_id",
  },
  {
    element: <BLACK_PAGE />,
    path: "home",
    role: "_id",
  },

  // ? class routes
  {
    element: <Class />,
    path: "/class/*",
    role: "getClasses",
  },
  {
    element: <Subjects />,
    path: "/subjects/*",
    role: "getSubjects",
  },
  {
    element: <Rooms />,
    path: "/rooms",
    role: "getRooms",
  },
  {
    element: <Students />,
    path: "/students/*",
    role: "getStudents",
  },
  {
    element: <Parents />,
    path: "/parents/*",
    role: "getParents",
  },

  // ? dashboard route
  {
    element: <Dashboard />,
    path: "dashboard",
    role: "_id",
  },

  // ? schedule route
  {
    element: <Schedule />,
    path: "schedule",
    role: "getLessons",
  },

  // ? task route
  {
    element: <Task />,
    path: "/task/*",
    role: "_id", //! must change
  },

  // ? journal route
  {
    element: <Journal />,
    path: "/journal/*",
    role: "getClasses",
  },

  // ? chat route
  {
    element: <Chat />,
    path: "/chat/*",
    role: "_id", //!must change
  },

  // ? monthly-assessment route
  {
    element: <MonthlyAssessment />,
    path: "/monthly-assessment/*",
    role: "getSeasonalMarks",
  },

  // ? analitics route
  {
    element: <Appropriation />,
    path: "/analitics/*",
    role: "_id", // ! must change
  },
  {
    element: <TeacherWorkReport />,
    path: "/analitics/teacher-work-reports/*",
    role: "getTeacherWorkReport",
  },

  // ? finance route
  {
    element: <Cash />,
    path: "/cash/*",
    role: "cashboxGet",
  },

  {
    element: <Salary />,
    path: "/salary/*",
    role: "_id",
  },
  {
    element: <MonthlySalary />,
    path: "/salary/monthly-salary",
    role: "_id",
  },

  // ? managment route
  // {
  //   element: <Managment />,
  //   path: "/managment",
  //   role: "_id",
  // },
  {
    element: <Branch />,
    path: "/branch",
    role: "getBranch",
  },
  {
    element: <Roles />,
    path: "/roles",
    role: "getRoles",
  },
  {
    element: <Employees />,
    path: "/employees/*",
    role: "getEmployees",
  },

  // ? settings routes
  {
    element: <LessonTime />,
    path: "/lesson-time",
    role: "getPeriod",
  },
  {
    element: <Buildings />,
    path: "/buildings",
    role: "getBuilding",
  },

  {
    element: <Quarter />,
    path: "quarter",
    role: "getQuarter",
  },
  {
    element: <EvalutionSystem />,
    path: "evalution-system",
    role: "getMarksSystem",
  },
  {
    element: <TransactionType />,
    path: "transaction-type",
    role: "transactionTypeGet",
  },
  {
    element: <PaymentMethod />,
    path: "payment-method",
    role: "paymentMethodsGet",
  },
  {
    element: <AbonimentList />,
    path: "aboniment/*",
    role: "subscriptionGet",
  },
  {
    element: <AbonimentInfo />,
    path: "aboniment/:id",
    role: "subscriptionGet",
  },
  {
    element: <Holiday />,
    path: "holiday",
    role: "subscriptionGet",
  },
  {
    element: <Receipt />,
    path: "receipt",
    role: "getReceipt",
  },
  {
    element: <Integrations />,
    path: "integrations",
    role: "integrations",
  },
];

export const teacherRoutes: (RouteObject & { role: keyof IRoles_ })[] = [
  {
    element: <Navigate to="/home" replace />,
    path: "*",
    role: "_id",
  },
  {
    element: <Navigate to="/home" replace />,
    path: "/",
    role: "_id",
  },
  {
    element: <BLACK_PAGE />,
    path: "home",
    role: "_id",
  },

  // ? time-table route
  {
    element: <Schedule />,
    path: "schedule",
    role: "getLessons",
  },

  // ? journal route
  {
    element: <Journal />,
    path: "/journal/*",
    role: "getClasses",
  },

  // ? chat route
  {
    element: <Chat />,
    path: "/chat/*",
    role: "_id", //!must change
  },
  // ? monthly-assessment route
  {
    element: <MonthlyAssessment />,
    path: "/monthly-assessment/*",
    role: "getSeasonalMarks",
  },
];
