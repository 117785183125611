import {
  BackButton,
  CommonButton,
  ExportButton,
  SearchInput,
} from "components";
import useAllQueryParams from "hooks/useGetAllQueryParams/useAllQueryParams";
import React from "react";
import { useTranslation } from "react-i18next";
import { TSetState } from "types/form.types";

const TableHeader: React.FC<{
  searchable?: boolean;
  exportBtn?: boolean;
  setSearch: TSetState<string>;
  search: string | undefined;
  title?: React.ReactNode | string;
  headerChildren: React.ReactNode;
  headerChildrenSecondRow: React.ReactNode;
  deletable: boolean;

  selectedRows: any[];
  onDelete: () => void;
  onAddButton?: () => void;
  addButtonTitle?: string;
  dataUrl?: string;
  back?: boolean;
  manualBackButton?: React.ReactNode;
}> = ({
  onAddButton,
  onDelete,

  setSearch,
  searchable,
  exportBtn,
  search,
  headerChildren,
  headerChildrenSecondRow,
  title,
  addButtonTitle,
  dataUrl,
  back,
  manualBackButton,
}) => {
  const { t } = useTranslation();

  const allParams = useAllQueryParams();

  const gradeArray = JSON.parse(allParams?.grade || "[]").map((grad: any) =>
    String(grad?._id)
  );

  const exportUrl = `/student/export?${
    gradeArray ? `grade=[${gradeArray}]&` : ""
  }
  ${
    allParams?.fromDate
      ? `fromDate=${encodeURIComponent(allParams?.fromDate)}&`
      : ""
  }
  ${allParams?.toDate ? `toDate=${encodeURIComponent(allParams?.toDate)}&` : ""}
  ${
    allParams?.showDebitors
      ? `showDebitors=${encodeURIComponent(allParams?.showDebitors)}&`
      : ""
  }
  ${allParams?.state ? `state=${encodeURIComponent(allParams?.state)}&` : ""}
  ${
    allParams?.hasContract
      ? `hasContract=${encodeURIComponent(allParams?.hasContract)}&`
      : ""
  }`.trim();

  return (
    <div className="table_header">
      <div className="d-flex justify-content-between align-items-center p-2">
        <div className="d-flex align-items-center gap-2">
          {back && <BackButton />}
          {manualBackButton}
          <h2 className="py-2 title">{title} </h2>
          {searchable && (
            <SearchInput
              value={search}
              onChange={(e: any) => {
                setSearch(e?.target?.value);
              }}
            />
          )}
        </div>
        <div className="d-flex justify-content-end align-items-center mb-2">
          <div className="actions d-flex align-items-center">
            {exportBtn && <ExportButton url={exportUrl} />}
            <div>{headerChildren}</div>
            {onAddButton && (
              <CommonButton
                title={addButtonTitle || t("general.add")!}
                className="main"
                onClick={() => {
                  onAddButton?.();
                }}
              />
            )}
            {/* {deletable && selectedRows?.length > 0 && (
              <Button
                style={{ borderRadius: 12 }}
                onClick={onDelete}
                sx={{ width: 40 }}
                variant="outlined"
                color="error"
                // disabled={selectedRows.length <= 0 ? true : false}
                className="table_add_button"
              >
                <DeleteIcon />
              </Button>
            )} */}
          </div>
        </div>
      </div>
      <div>{headerChildrenSecondRow}</div>
    </div>
  );
};

export default TableHeader;
