import { BackButton, RangeDatePicker, Table } from "components";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useColumns } from "./one.columns";
import { useParams } from "react-router-dom";
import { ITeacherWorkReportInfo } from "../../types";

export const One = () => {
  const thisMonthStart = dayjs().startOf("month").toISOString();
  const thisMonthEnd = dayjs().toISOString();

  const columns = useColumns();
  const { t } = useTranslation();
  const { id } = useParams();

  const renderHeader = (
    <div
      style={{
        display: "flex",
        gap: "10px",
        alignItems: "center",
      }}
    >
      <RangeDatePicker
        defaultValues={[dayjs(thisMonthStart), dayjs(thisMonthEnd)]}
      />
    </div>
  );

  return (
    <div>
      <Table<ITeacherWorkReportInfo>
        dataUrl={`/analitics/journal-activity/info`}
        columns={columns}
        title={t("general.teacher-work-report")}
        headerChildren={renderHeader}
        exQueryParams={{
          fromDate: thisMonthStart,
          toDate: thisMonthEnd,
        }}
        processingParams={(params) => {
          return {
            ...params,
            teacherIds: JSON.stringify([id]),
          };
        }}
        back={false}
        manualBackButton={<BackButton to="/analitics/teacher-work-reports" />}
      />
    </div>
  );
};
