import { GridColumns } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { IParent } from "../types";
import { Cancel, CheckCircle } from "components/common/Icons";

export const useRoomColumns = (): GridColumns<IParent> => {
  const { t } = useTranslation();

  return [
    {
      field: t("TABLE.NAME_SURNAME"),
      renderCell(params) {
        return params.row?.fullName;
      },
    },
    {
      field: t("TABLE.RELATION"),
      renderCell(params) {
        return t(`general.${params.row.type}`);
      },
    },
    {
      field: t("TABLE.CHILDREN"),
      renderCell(params) {
        return params.row.studentIds
          .map(
            (item) =>
              `${item?.student?.firstName} ${item?.student?.lastName} - ${item?.studentClass?.grade}${item?.studentClass?.letter}`
          )
          .join("\n");
      },
    },
    {
      field: t("TABLE.IS_APP_INSTALLED"),
      renderCell(params) {
        return params.row.isAppInstalled ? <CheckCircle /> : <Cancel />;
      },
    },
    {
      field: t("TABLE.LAST_SEEN"),
      renderCell(params) {
        if (!params.row.lastActiveAt) return null;
        return new Date(params.row.lastActiveAt).toLocaleString("ru");
      },
    },
  ];
};
