import { GridColumns } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { ITeacherWorkReport } from "../../types";
import { CustomLink } from "styles/global.style";
import { useNavigate } from "react-router-dom";
import { get } from "lodash";

export const useRoomColumns = (): GridColumns<ITeacherWorkReport> => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return [
    {
      field: t("TABLE.NAME_SURNAME"),
      renderCell(params) {
        return params.row.teacher.firstName + " " + params.row.teacher.lastName;
      },
    },
    {
      field: t("TABLE.LESSON_COUNT"),
      renderCell(params) {
        return params.row.totalTeacherLessonsCount;
      },
    },
    {
      field: t("TABLE.INTERACTIONS_COUNT"),
      renderCell(params) {
        return (
          <CustomLink
            onClick={() =>
              navigate(
                `/analitics/teacher-work-reports/${get(params, "id", "")}`
              )
            }
          >
            {params.row.totalInteractionsCount}
          </CustomLink>
        );
      },
    },
    {
      field: t("TABLE.HOMEWORK_COUNT"),
      renderCell(params) {
        return (
          <CustomLink
            onClick={() =>
              navigate(
                `/analitics/teacher-work-reports/${get(params, "id", "")}`
              )
            }
          >
            {params.row.totalGivenHomeworkLessonsCount}
          </CustomLink>
        );
      },
    },
  ];
};
