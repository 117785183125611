import { FC } from "react";
import { Route, Routes } from "react-router-dom";
import { List } from "./container/list/list";
import { One } from "./container/one/one";

export interface ITeacherWorkReportProps {}

const TeacherWorkReport: FC<ITeacherWorkReportProps> = () => {
  return (
    <Routes>
      <Route path="" element={<List />} />
      <Route path=":id" element={<One />} />
    </Routes>
  );
};
export default TeacherWorkReport;
